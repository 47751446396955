import { Button } from "antd";
import { styled } from "../../styles/theme";

export const PrimaryButtonStyled = styled(Button)`
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.sgLightGray : theme.sgDarkGray} !important;
  border-color: ${({ theme, disabled }) =>
    disabled ? theme.sgLightGray : theme.sgDarkGray} !important;
  color: white;
  padding: 0px 20px;
`;
