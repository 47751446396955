import { Status } from "../../common";
import { theme } from "../../styles/theme";

const chipTypes = new Map<Status, { color: string; text: string }>();
chipTypes.set("ready", { color: theme.sgDarkGray, text: "Ready" });
chipTypes.set("inProgress", { color: theme.inProgress, text: "In Progress" });
chipTypes.set("failed", { color: theme.failed, text: "Failed" });
chipTypes.set("success", { color: theme.valid, text: "Success" });

export const getChipTypeContent = (type: Status) => {
  return chipTypes.get(type)!;
};
