import baseStyled, { ThemedStyledInterface } from "styled-components";

export const theme = {
  sgViolet: "#321850",
  onVioletItems: "rgba(255, 255, 255, 0.1)",
  sgDarkGray: "#3c3c3c",
  sgLightGray: "#D3D3D3",
  transparentBg: "rgba(0, 0, 0, 0.1)",
  valid: "#00AFAD",
  failed: "#FF4C5B",
  inProgress: "#FAB600",
};

export type Theme = typeof theme;
export const styled = baseStyled as ThemedStyledInterface<Theme>;
