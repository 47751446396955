import { styled } from "../../styles/theme";

export const PageWrapper = styled.div`
  display: flex;
`;

export const TenantCreationDataContainer = styled.div`
  background-color: white;
  padding: 10px;
  flex: 0.4;
  margin-right: 10px;
`;

export const PageActions = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
`;
