import { useState, useEffect, useContext } from "react";
import UserDetailsContext, {
  UserDetailsValueType,
} from "./UserDetails.context";
import * as Utils from "hermes-tiles-grid-popup";

const useAuth = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [apps, setApps] = useState<any | Array<any>>([]);
  const [userId, setUserId] = useState("");
  const [logoUrl, setLogoUrl] = useState<string>();

  const { user } = useContext<UserDetailsValueType>(UserDetailsContext);

  const setUserDetails = (user: any) => {
    const { given_name, family_name, apps, sub } = user.profile;
    setFirstName(given_name);
    setLastName(family_name);
    setApps(apps);
    setUserId(sub);
    setLogoUrl(Utils.extractLogoUrlFromToken(user.profile));
  };

  useEffect(() => {
    if (user) {
      setUserDetails(user);
    }
  }, [user]);

  return { firstName, lastName, apps, userId, logoUrl };
};

export default useAuth;
