import React from "react";
import { Progress } from "antd";
import { StepMessage, StepStatusContainer } from "./StepStatus.style";
import { getStepTypeContent, prepareStatusValue } from "./utils";
import { StepStatus as StepStatusEnum } from "../../../types/creationStatus";

type Props = {
  status: StepStatusEnum;
};

const StepStatus = ({ status }: Props) => {
  const statusSettings = getStepTypeContent(prepareStatusValue(status));

  return (
    <StepStatusContainer>
      <StepMessage>{statusSettings.text}</StepMessage>
      <Progress
        showInfo={false}
        percent={statusSettings.percentage}
        status={statusSettings.status}
        strokeColor={statusSettings.color}
      />
    </StepStatusContainer>
  );
};

export default StepStatus;
