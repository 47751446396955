import { styled } from "../../styles/theme";

export const BasicChip = styled.span`
  padding: 6px 10px;
  border-radius: 40px;
  height: 21px;
  background-color: ${(props) => props.color};
  font-size: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: fit-content;
`;
