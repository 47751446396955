import { combineReducers } from "@reduxjs/toolkit";
import homeReducer from "../pages/Home/Home.slice";

const rootReducer = combineReducers({
  home: homeReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
