import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../redux/store";
import { CreateTenant, Tenant } from "../../types/tenant";
import { CreationStatus } from "../../types/creationStatus";
import { ApiFactory } from "../../services/ApiFactory";

const tenantApiService = ApiFactory.getTenantApiService();

type State = {
  isLoading: boolean;
  isCreating: boolean;
  tenants: Tenant[];
  selectedTenant: Tenant | null;
  creationStatuses: CreationStatus | null;
};

const initialState: State = {
  isLoading: false,
  isCreating: false,
  tenants: [],
  selectedTenant: null,
  creationStatuses: null,
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setIsCreating(state, action: PayloadAction<boolean>) {
      state.isCreating = action.payload;
    },
    setTenants(state, action: PayloadAction<Tenant[]>) {
      state.tenants = action.payload;
    },
    setTenant(state, action: PayloadAction<Tenant | null>) {
      state.selectedTenant = action.payload;
    },
    setSteps(state, action: PayloadAction<CreationStatus | null>) {
      state.creationStatuses = action.payload;
    },
    clearTenant(state) {
      state.selectedTenant = null;
      state.creationStatuses = null;
    },
  },
});

export const {
  setIsLoading,
  setTenants,
  setTenant,
  setIsCreating,
  setSteps,
  clearTenant,
} = homeSlice.actions;

export default homeSlice.reducer;

export const getTenants =
  (hideSpinner: boolean = false): AppThunk =>
  async (dispatch) => {
    if (!hideSpinner) {
      dispatch(setIsLoading(true));
    }

    const tenants = await tenantApiService.getTenants();
    if (tenants) {
      dispatch(setTenants(tenants));
    }

    if (!hideSpinner) {
      dispatch(setIsLoading(false));
    }
  };

export const getTenant =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));

    const tenant = await tenantApiService.getTenant(id);
    if (tenant) {
      dispatch(setTenant(tenant));
      dispatch(setSteps(null));
      dispatch(getSteps(tenant.name));
    }

    dispatch(setIsLoading(false));
  };

export const createTenant =
  (tenant: CreateTenant): AppThunk =>
  async (dispatch) => {
    dispatch(setIsCreating(true));

    const data = await tenantApiService.createTenant(tenant);
    if (data) {
      dispatch(setTenant(data));
      dispatch(getTenants());
      dispatch(getSteps(data.name));
    }

    dispatch(setIsCreating(false));
  };

export const editTenant =
  (id: string, tenant: CreateTenant): AppThunk =>
  async (dispatch) => {
    dispatch(setIsCreating(true));

    await tenantApiService.editTenant(id, tenant);

    dispatch(setIsCreating(false));
  };

export const retryTenant =
  (id: string): AppThunk =>
  async (dispatch, getState) => {
    const { selectedTenant } = getState().home;

    dispatch(setIsCreating(true));

    await tenantApiService.retryTenant(id);

    if (selectedTenant) {
      dispatch(getTenants(true));
      dispatch(getSteps(selectedTenant.name));
    }

    dispatch(setIsCreating(false));
  };

export const getSteps =
  (tenantName: string): AppThunk =>
  async (dispatch) => {
    const data = await tenantApiService.getSteps(tenantName);
    if (data) {
      dispatch(setSteps(data));
    }
  };
