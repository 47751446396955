import React from "react";
import CenteredHeader from "../components/CenteredHeader/CenteredHeader";

const messageContent = "You are not authorized to see this page.";

const IdentityError = () => {
  return <CenteredHeader content={messageContent} />;
};

export default IdentityError;
