import { Navigate, useLocation } from "react-router";
import CenteredHeader from "../components/CenteredHeader/CenteredHeader";
import { useCallback, useEffect, useState } from "react";
import { AuthService } from "../services/AuthService";

const Login = () => {
  const [error, setError] = useState(false);
  const [returnUrl, setReturnUrl] = useState({});
  const location = useLocation();

  const login = useCallback(async () => {
    try {
      await AuthService.login({
        state: returnUrl,
      });
    } catch (error) {
      setError(true);
    }
  }, [returnUrl]);

  useEffect(() => {
    if (location.state && location.state.hasOwnProperty("from")) {
      const returnUrl: string = (location.state as any).from;
      setReturnUrl(returnUrl);
    }

    login();
  }, [location.state, returnUrl, login]);

  if (error) {
    return <Navigate replace to="/identity-error" />;
  }

  return <CenteredHeader content="Redirecting to identity provider..." />;
};

export default Login;
