import { Dropdown, Menu } from "antd";
import { DownOutlined, LogoutOutlined } from "@ant-design/icons";
import {
  HeaderContainer,
  HeaderRight,
  Logo,
  AppLogoContainer,
  SgLogoContainer,
  UserInfoContainer,
  UserName,
  UserSelect,
  UserSettings,
} from "./Header.style";
import appLogo from "../../app-logo.png";
import sgLogo from "../../sg-logo.png";
import { AuthService } from "../../services/AuthService";
import useAuth from "../../common/useAuth";
import { useNavigate } from "react-router";

const menu = (
  <Menu>
    <Menu.Item>Settings</Menu.Item>
  </Menu>
);

const Header = () => {
  const { firstName, lastName } = useAuth();
  const navigate = useNavigate();

  const logout = () => {
    AuthService.logout();
  };

  return (
    <HeaderContainer>
      <AppLogoContainer>
        <Logo src={appLogo} onClick={() => navigate("/")} />
      </AppLogoContainer>
      <HeaderRight>
        <UserInfoContainer>
          <UserName>
            <Dropdown overlay={menu}>
              <UserSelect onClick={(e) => e.preventDefault()}>
                <span>
                  {firstName} {lastName}
                </span>
                <DownOutlined />
              </UserSelect>
            </Dropdown>
          </UserName>
          <UserSettings>EN</UserSettings>
          <UserSettings>SR</UserSettings>
          <UserSettings onClick={logout}>
            <LogoutOutlined />
          </UserSettings>
        </UserInfoContainer>
        <SgLogoContainer>
          <Logo src={sgLogo} />
        </SgLogoContainer>
      </HeaderRight>
    </HeaderContainer>
  );
};

export default Header;
