import { ColumnsType } from "antd/lib/table/interface";
import { createSelector } from "reselect";
import { RootState } from "../../redux/rootReducer";
import { StepStatus as StepStatusEnum } from "../../types/creationStatus";
import StepResult from "./StepResult/StepResult";
import StepStatus from "./StepStatus/StepStatus";
import { allSteps } from "../../common/anyFailedStepSelector";

const getStatusSteps = (state: RootState) => state.home.creationStatuses;

export const prepareStepStatusesDataSelector = createSelector(
  [getStatusSteps],
  (statusSteps) => {
    if (!statusSteps) {
      return {
        columns,
        dataSource: allSteps.map((step) => ({
          key: step.name,
          name: step.name,
          status: StepStatusEnum.NotStarted,
        })),
      };
    }

    const dataSource = allSteps.map((step) => ({
      key: step.name,
      name: step.name,
      status: step.field(statusSteps),
      exceptionMessageHierarchy: statusSteps.exceptionMessageHierarchy,
      exceptionMessage: statusSteps.exceptionMessage,
      stacktrace: statusSteps.stacktrace,
    }));

    return {
      columns,
      dataSource,
    };
  }
);

export type StepRow = {
  key: string;
  name: string;
  status: StepStatusEnum;
  exceptionMessageHierarchy?: string;
  exceptionMessage?: string;
  stacktrace?: string;
};

export type GridSelectorResult = {
  columns: ColumnsType<any>;
  dataSource: StepRow[];
};

const columns: ColumnsType<StepRow> = [
  {
    title: "Step",
    dataIndex: "name",
    key: "key",
    width: "120px",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    render: (value: any, record: StepRow) => (
      <StepStatus status={record.status} />
    ),
  },
  {
    title: "Result",
    dataIndex: "result",
    key: "result",
    render: (value: any, record: StepRow) => (
      <StepResult status={record.status} />
    ),
    width: "60px",
  },
];
