export type CreationStatus = {
  tenantId: string;
  awsResoruces: StepStatus;
  sqlServer: StepStatus;
  postgreSQL: StepStatus;
  mongoDB: StepStatus;
  elasticsearch: StepStatus;
  defaultData: StepStatus;
  mlServices: StepStatus;
  exceptionMessageHierarchy: string;
  exceptionMessage: string;
  stacktrace: string;
};

export enum StepStatus {
  NotStarted = 0,
  InProgress = 1,
  Ready = 2,
  Failed = 3,
}
