import { styled } from "../../styles/theme";

export const CenteredHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 80px); //header height is 80px
`;

export const CenteredHeaderH2 = styled.h2``;
