import { Routes, Route, Navigate } from "react-router-dom";
import Callback from "./auth/Callback";
import Login from "./auth/Login";
import NotAuthorized from "./auth/NotAuthorized";
import IdentityError from "./auth/IdentityError";
import RequireAuth from "./auth/RequireAuth";
import Home from "./pages/Home/Home";
import TenantDetails from "./pages/TenantDetails/TenantDetails";
import NoMatch from "./pages/NoMatch/NoMatch";

const Router = () => {
  return (
    <Routes>
      <Route path="/callback" element={<Callback />} />
      <Route path="/login" element={<Login />} />
      <Route path="/not-authorized" element={<NotAuthorized />} />
      <Route path="/identity-error" element={<IdentityError />} />
      <Route
        path="/tenants/:id"
        element={<RequireAuth Component={TenantDetails} />}
      />
      <Route path="/tenants" element={<RequireAuth Component={Home} />} />
      <Route path="/" element={<Navigate to="/tenants" />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

export default Router;
