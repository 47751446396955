import { User } from "oidc-client";
import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router";
import CenteredHeader from "../components/CenteredHeader/CenteredHeader";
import { AuthService } from "../services/AuthService";

type Props = {
  Component: React.ElementType;
};

const RequireAuth = ({ Component }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);

  const location = useLocation();

  const getUserHandle: (user: User | null) => void = (user) => {
    const isAuthenticated = !!(user && user.expires_at > Date.now() / 1000);
    const hasPermission = !!(user && user.profile && user.profile.super_admin);

    setHasPermission(hasPermission);
    setIsAuthenticated(isAuthenticated);
    setIsLoading(false);
  };

  useEffect(() => {
    async function prepareUser() {
      try {
        const user = await AuthService.getUser();
        await getUserHandle(user);
      } catch (error) {
        setIsLoading(false);
      }
    }

    prepareUser();
  }, []);

  if (isLoading) {
    return <CenteredHeader content="Loading..." />;
  }

  if (!isAuthenticated) {
    return (
      <Navigate
        replace
        to={{ pathname: "/login" }}
        state={{ from: location.pathname }}
      />
    );
  }

  if (!hasPermission) {
    return (
      <Navigate
        replace
        to={{ pathname: "/not-authorized" }}
        state={{ from: location.pathname }}
      />
    );
  }

  return <Component />;
};

export default RequireAuth;
