import React from "react";
import { StepRow } from "../gridSelector";
import {
  ErrorToolitipMessageContainer,
  MessageContainer,
  MessageContent,
  MessageTitle,
} from "./ErrorToolitipMessage.style";

type Props = {
  row: StepRow;
};

const ErrorToolitipMessage = ({ row }: Props) => {
  return (
    <ErrorToolitipMessageContainer>
      <MessageContainer>
        <MessageTitle>Error message</MessageTitle>
        <MessageContent>{row.exceptionMessage}</MessageContent>
      </MessageContainer>
      <MessageContainer>
        <MessageTitle>Inner exception</MessageTitle>
        <MessageContent>{row.stacktrace}</MessageContent>
      </MessageContainer>
    </ErrorToolitipMessageContainer>
  );
};

export default ErrorToolitipMessage;
