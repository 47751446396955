import { styled } from "../../../../styles/theme";

export const PageWrapper = styled.div`
  background-color: white;
  padding: 10px;
  display: flex;
`;

export const PageActions = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 10px;
`;

export const FormContainer = styled.div`
  flex: 1;
  margin-right: 23px;
`;

export const StepsContainer = styled.div`
  flex: 1;
`;
