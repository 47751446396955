import React, { ReactNode } from "react";
import {
  CenteredHeaderContainer,
  CenteredHeaderH2,
} from "./CenteredHeader.style";

type Props = {
  content: ReactNode;
};

const CenteredHeader = ({ content }: Props) => {
  return (
    <CenteredHeaderContainer>
      <CenteredHeaderH2>{content}</CenteredHeaderH2>
    </CenteredHeaderContainer>
  );
};

export default CenteredHeader;
