import axiosConfig, { AxiosResponse } from "axios";
import { showInfoToast } from "../common";

const GENERIC_ERROR_MESSAGE = "An error occurred";

export function handleResponse<T>(response: AxiosResponse<T, any>) {
  if (!response.data) {
    return {} as T;
  }

  return response.data;
}

export function handleError(error: any) {
  let toastMessage = GENERIC_ERROR_MESSAGE;

  if (axiosConfig.isAxiosError(error) && error.response) {
    if (typeof error.response.data === "string") {
      showInfoToast(error.response.data);
    }
  }

  showInfoToast(toastMessage);
  console.log(error);
}
