import { Table } from "antd";
import { Tenant } from "../../../types/tenant";
import {
  TenantApplicationContainer,
  HeaderH3,
  PageHeader,
  TableContent,
} from "./TenantApplications.style";
import { prepareTableData } from "./utils";

type Props = {
  tenant: Tenant;
};

const TenantApplications = ({ tenant }: Props) => {
  const tableConfig = prepareTableData(tenant);

  return (
    <TenantApplicationContainer>
      <PageHeader>
        <HeaderH3>Tenant Applications</HeaderH3>
      </PageHeader>
      <TableContent className="applications-table">
        <Table
          scroll={{
            y: "62vh",
          }}
          dataSource={tableConfig.dataSource}
          columns={tableConfig.columns}
          pagination={false}
        />
      </TableContent>
    </TenantApplicationContainer>
  );
};

export default TenantApplications;
