import { Input } from "antd";
import { styled } from "../../styles/theme";

export const BaseDivWithPadding = styled.div`
  padding: 10px;
`;

export const PageWrapper = styled(BaseDivWithPadding)`
  background-color: white;
`;

export const TableFilters = styled(BaseDivWithPadding)``;

export const TableContent = styled(BaseDivWithPadding)``;

export const PageActions = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 10px;
`;

export const InputSearch = styled(Input)`
  width: 270px;
  height: 30px;
  background-color: ${({ theme }) => theme.transparentBg};
  border: none;
  border-radius: 2px;

  input {
    background-color: transparent;
  }
`;
