import React from "react";
import { PrimaryButtonStyled } from "./Button.style";

type Props = {
  title: string;
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
};

const PrimaryButton = ({ title, disabled, loading, onClick }: Props) => {
  return (
    <PrimaryButtonStyled
      type="primary"
      onClick={onClick}
      disabled={disabled}
      loading={loading}
    >
      {title}
    </PrimaryButtonStyled>
  );
};

export default PrimaryButton;
