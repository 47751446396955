import React from "react";
import CenteredHeader from "../components/CenteredHeader/CenteredHeader";

const messageContent =
  "An error in communication with the Identity Server happend";

const IdentityError = () => {
  return <CenteredHeader content={messageContent} />;
};

export default IdentityError;
