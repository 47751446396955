import axios from "axios";
import * as Utils from "hermes-tiles-grid-popup";
import { showInfoToast } from "./common";
import { AuthService } from "./services/AuthService";

const apiBaseUrl = Utils.replaceTenantNamePlaceHolder(
  process.env.REACT_APP_TM_API!
);

const instance = axios.create({
  baseURL: apiBaseUrl,
});

instance.interceptors.request.use(
  (config) => {
    return AuthService.getUser().then((user) => {
      if (user != null && user.access_token != null) {
        const { access_token } = user;
        config.headers!.Authorization = `Bearer ${access_token}`;
      }
      return Promise.resolve(config);
    });
  },
  (err) => {
    return Promise.reject(err);
  }
);

instance.interceptors.response.use(
  (config) => config,
  (err) => {
    if (err && err.response) {
      if (err.response.status === 401) {
        AuthService.login();
      }
      if (err.response.status === 403) {
        showInfoToast("You do not have permissions to access this app!");
      }
      if (err.response.status >= 500 && err.response.status <= 511) {
        showInfoToast(
          "A server error occurred. Please contact the administrator."
        );
      }
    }
    return Promise.reject(err);
  }
);

export { instance as axios };
