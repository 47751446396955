import { notification, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
const { confirm } = Modal;

export const showInfoToast = (title: string) => {
  notification.open({
    message: `${title}`,
    placement: "bottomLeft",
    duration: 2.5,
    style: {
      color: "#321850",
    },
  });
};

export const showConfirm = (message: string, onOk: (...args: any[]) => any) => {
  confirm({
    title: message,
    icon: <ExclamationCircleOutlined />,
    onOk,
  });
};

export type Status = "ready" | "inProgress" | "failed" | "success";

export const validTenantNameRegex = new RegExp(/^[A-Z][a-z0-9]{1,14}$/);
