import { CreateTenant, Tenant } from "../types/tenant";
import { showInfoToast } from "../common";
import { BaseApiService } from "./BaseApiService";
import { CreationStatus } from "../types/creationStatus";

export class TenantApiService extends BaseApiService {
  async getTenants() {
    const data = await this.post<Tenant[]>(`api/tenant/all`, {});
    return data;
  }

  async getTenant(id: string) {
    const data = await this.get<Tenant>(`api/tenant/${id}`);
    return data;
  }

  async createTenant(tenant: CreateTenant) {
    const formData = new FormData();
    formData.set("name", tenant.name!);
    formData.set("fullName", tenant.fullName!);
    formData.set("file", tenant.file!);

    showInfoToast("Tenant creation started");

    const data = await this.post<Tenant>(`api/tenant`, formData);
    if (data) {
      showInfoToast("Tenant creation finished");
      return data;
    }
  }

  async editTenant(id: string, tenant: CreateTenant) {
    const formData = new FormData();
    formData.set("file", tenant.file!);

    const data = await this.put<Tenant>(`api/tenant/${id}`, formData);
    if (data) {
      showInfoToast("Tenant successfully updated");
    }
  }

  async retryTenant(id: string) {
    showInfoToast("Tenant creation retry started");
    const data = await this.get<Tenant>(`api/tenant/retry/${id}`);
    if (data) {
      showInfoToast("Tenant creation retry finished");
    }
  }

  async getTenantLogo(id: string) {
    const data = await this.get<any>(`api/tenant/${id}/logo`, {
      responseType: "blob",
    });

    if (!data) {
      return;
    }

    let metadata = {
      type: "image/png",
    };
    let file = new File([data], "test.png", metadata);

    return file;
  }

  async getSteps(tenantName: string) {
    const data = await this.get<CreationStatus>(
      `api/tenant/steps/${tenantName}`
    );
    return data;
  }
}
