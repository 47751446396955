import { useCallback, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import CenteredHeader from "../components/CenteredHeader/CenteredHeader";
import { AuthService } from "../services/AuthService";

const messageContent = "Authentification callback processing...";

const Callback = () => {
  const [loaded, setLoaded] = useState(false);
  const [failed, setFailed] = useState(false);
  const [redirectRoute, setRedirectRoute] = useState<string | null>("");

  const signIn = useCallback(async () => {
    try {
      const user = await AuthService.signinRedirectCallback();
      setRedirectRoute(user.state);
      setLoaded(true);
    } catch (error) {
      console.error(error);
      setFailed(true);
    }
  }, []);

  useEffect(() => {
    signIn();
  }, [signIn]);

  if (failed) {
    return <Navigate replace to="/identity-error" />;
  }

  if (loaded) {
    if (redirectRoute) {
      return <Navigate replace to={redirectRoute} />;
    } else {
      return <Navigate replace to="/" />;
    }
  }

  return <CenteredHeader content={messageContent} />;
};

export default Callback;
