import { motion } from "framer-motion";
import { styled } from "../../../styles/theme";

export const CropContainer = styled(motion.div)`
  min-height: 75px;
`;

export const CropContainerText = styled(CropContainer)`
  display: flex;
  flex-direction: column;
`;
