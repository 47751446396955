import { motion } from "framer-motion";
import { styled } from "../../styles/theme";

export const PageContentContainer = styled(motion.main)`
  background-color: white;
  padding: 0px 10px;
`;

export const PageHeader = styled.div`
  margin: 15px 0;
`;

export const HeaderH3 = styled.h3`
  font-weight: 200;
  margin-left: 30px;
`;

export const PageContent = styled.main`
  background-color: ${({ theme }) => theme.transparentBg};
  padding: 10px;
`;
