import { SmallLogoContainer, SmallLogoStyle } from "./SmallLogo.style";

type Props = {
  logoUrl: string;
};

const SmallLogo = ({ logoUrl }: Props) => {
  if (!logoUrl) {
    return null;
  }

  return (
    <SmallLogoContainer>
      <SmallLogoStyle src={logoUrl} alt="logo" />
    </SmallLogoContainer>
  );
};

export default SmallLogo;
