import { styled } from "../../../styles/theme";

export const ErrorToolitipMessageContainer = styled.div`
  padding: 10px;
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MessageTitle = styled.span`
  font-weight: bold;
`;

export const MessageContent = styled.span``;
