import React, { useEffect, useState } from "react";
import PageContainer from "../../components/PageContainer/PageContainer";
import { CreateTenant, Tenant } from "../../types/tenant";
import {
  PageActions,
  PageWrapper,
  TenantCreationDataContainer,
} from "./TenantDetails.style";
import PrimaryButton from "../../components/Button/PrimaryButton";
import TenantForm from "../../components/TenantForm/TenantForm";
import CreationSteps from "../../components/CreationSteps/CreationSteps";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { AppDispatch } from "../../redux/store";
import {
  clearTenant,
  editTenant,
  getTenant,
  retryTenant,
  setTenant,
} from "../Home/Home.slice";
import CenteredHeader from "../../components/CenteredHeader/CenteredHeader";
import TenantApplications from "./TenantApplications/TenantApplications";
import { ApiFactory } from "../../services/ApiFactory";
import { getAnyFailedStepSelector } from "../../common/anyFailedStepSelector";

type Props = {
  isLoading: boolean;
  isCreating: boolean;
  selectedTenant: Tenant | null;
  anyFailed: boolean;
  onGetTenant: (id: string) => void;
  onEdit: (id: string, tenant: CreateTenant) => void;
  onRetry: (name: string) => void;
  onClearTenant: () => void;
};

const TenantDetails = ({
  isLoading,
  isCreating,
  selectedTenant,
  anyFailed,
  onGetTenant,
  onEdit,
  onRetry,
  onClearTenant,
}: Props) => {
  const [file, setFile] = useState<File>();
  const [newFile, setNewFile] = useState<File>();
  const [tenantName, setTenantName] = useState("");
  const [tenantFullName, setTenantFullName] = useState("");

  let params = useParams();
  let navigation = useNavigate();

  useEffect(() => {
    if (
      params.id &&
      (!selectedTenant || (selectedTenant && selectedTenant.id !== params.id))
    ) {
      onGetTenant(params.id);
    }
  }, [onGetTenant, params, selectedTenant]);

  useEffect(() => {
    async function getLogo() {
      const file = await ApiFactory.getTenantApiService().getTenantLogo(
        selectedTenant!.id
      );
      if (file) {
        setFile(file);
        setNewFile(file);
      }
    }
    if (selectedTenant) {
      setTenantName(selectedTenant.name);
      setTenantFullName(selectedTenant.fullCompanyName);

      getLogo();
    }
    return () => {};
  }, [selectedTenant]);

  useEffect(() => {
    return () => {
      onClearTenant();
    };
  }, [onClearTenant]);

  const edit = () => {
    if (!selectedTenant) {
      return;
    }

    const newTenant: CreateTenant = {
      file: newFile,
      name: tenantName,
      fullName: tenantFullName,
    };
    onEdit(selectedTenant.id, newTenant);
  };

  const retry = () => {
    if (!selectedTenant) {
      return;
    }

    onRetry(selectedTenant.id);
  };

  const back = () => {
    navigation("/");
  };

  if (isLoading || !selectedTenant) {
    return <CenteredHeader content="Loading..." />;
  }

  const canEdit = tenantName.length && tenantFullName.length && newFile;

  return (
    <PageContainer pageTitle={selectedTenant.name}>
      <PageWrapper>
        <TenantCreationDataContainer>
          <TenantForm
            {...{
              file,
              newFile,
              tenantName,
              tenantFullName,
              readonly: true,
              setFile,
              setNewFile,
              setTenantName,
              setTenantFullName,
            }}
          />
          <CreationSteps tenantName={tenantName} />
        </TenantCreationDataContainer>
        <TenantApplications tenant={selectedTenant} />
      </PageWrapper>
      <PageActions>
        <PrimaryButton title="Back" onClick={back} />
        <>
          {!anyFailed && (
            <PrimaryButton
              title="Edit"
              onClick={edit}
              disabled={!canEdit}
              loading={isCreating}
            />
          )}
          {anyFailed && (
            <PrimaryButton
              title="Retry"
              onClick={retry}
              disabled={!canEdit}
              loading={isCreating}
            />
          )}
        </>
      </PageActions>
    </PageContainer>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: state.home.isLoading,
  isCreating: state.home.isCreating,
  selectedTenant: state.home.selectedTenant,
  anyFailed: getAnyFailedStepSelector(state),
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onGetTenant: (id: string) => dispatch(getTenant(id)),
  onEdit: (id: string, tenant: CreateTenant) =>
    dispatch(editTenant(id, tenant)),
  onRetry: (id: string) => dispatch(retryTenant(id)),
  onClearTenant: () => dispatch(clearTenant()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TenantDetails);
