import { AxiosRequestConfig } from "axios";
import { axios } from "../axios";
import { handleError, handleResponse } from "./ApiHelpers";

export class BaseApiService {
  async get<T>(endpoint: string, config?: AxiosRequestConfig<any>) {
    try {
      const response = await axios.get<T>(endpoint, config);
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  }

  async post<T>(endpoint: string, body: any, config?: AxiosRequestConfig<any>) {
    try {
      const response = await axios.post<T>(endpoint, body, config);
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  }

  async put<T>(endpoint: string, body: any, config?: AxiosRequestConfig<any>) {
    try {
      const response = await axios.put<T>(endpoint, body, config);
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  }
}
