import { Input, Tooltip } from "antd";
import { Container, FormItemContainer } from "./TenantForm.style";
import UploadAndCrop from "./UploadAndCrop/UploadAndCrop";
import { InfoCircleOutlined } from "@ant-design/icons";
import { theme } from "../../styles/theme";
import { validTenantNameRegex } from "../../common";

type Props = {
  file?: File;
  newFile?: File;
  tenantName: string;
  tenantFullName: string;
  readonly?: boolean;
  setFile: (file?: File) => void;
  setNewFile: (file?: File) => void;
  setTenantName: (tenantName: string) => void;
  setTenantFullName: (tenantFullName: string) => void;
};

const TenantForm = ({
  readonly,
  file,
  newFile,
  tenantName,
  tenantFullName,
  setFile,
  setNewFile,
  setTenantName,
  setTenantFullName,
}: Props) => {
  const isNameValid = validTenantNameRegex.test(tenantName);
  const tenantNameClass =
    tenantName.length > 0 && !readonly
      ? isNameValid
        ? "valid"
        : "not-valid"
      : "";
  const infoBaseColor = "rgba(0,0,0,.45)";
  const infoColor =
    tenantName.length > 0
      ? isNameValid
        ? infoBaseColor
        : theme.failed
      : infoBaseColor;

  return (
    <Container>
      <div>
        <label>Upload Your Image</label>
        <UploadAndCrop
          {...{ file, setFile, newFile, setNewFile, isEdit: readonly }}
        />
      </div>
      <FormItemContainer>
        <label>Tenant Name</label>
        <Input
          className={tenantNameClass}
          disabled={readonly}
          value={tenantName}
          onChange={(event) => setTenantName(event.target.value)}
          suffix={
            <Tooltip title="Name is one word, first letter capital, rest the small case, no special characters, numbers allowed, limit to 15 chars">
              <InfoCircleOutlined style={{ color: infoColor }} />
            </Tooltip>
          }
        />
      </FormItemContainer>
      <FormItemContainer>
        <label>Tenant Full Name</label>
        <Input
          disabled={readonly}
          value={tenantFullName}
          onChange={(event) => setTenantFullName(event.target.value)}
        />
      </FormItemContainer>
    </Container>
  );
};

export default TenantForm;
