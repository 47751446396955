import { ColumnsType } from "antd/lib/table/interface";
import { createSelector } from "reselect";
import { Status } from "../../common";
import Chip from "../../components/Chip/Chip";
import { RootState } from "../../redux/rootReducer";
import { StepStatus } from "../../types/creationStatus";
import { TenantRow } from "../../types/tenant";
import SmallLogo from "./components/SmallLogo/SmallLogo";

const getGrid = (state: RootState) => state.home.tenants;

export const prepareTenantsGridDataSelector = createSelector(
  [getGrid],
  (gridData) => {
    if (!gridData) {
      return {
        columns: [],
        dataSource: [],
      };
    }

    const dataSource: TenantRow[] = gridData.map((tenant) => ({
      key: tenant.id,
      id: tenant.id,
      name: tenant.name,
      fullName: tenant.fullCompanyName,
      logo: tenant.logoUrl,
      mlStatus: parseMlStatus(tenant.mlInfrastructureCreated),
      vbaiStatus: parseVBAIStatus(tenant.vbaiInfrastructureStatus),
    }));

    return {
      columns,
      dataSource,
    };
  }
);

export type GridSelectorResult = {
  columns: ColumnsType<any>;
  dataSource: TenantRow[];
};

const parseVBAIStatus = (vbaiInfrastructureStatus?: StepStatus): Status => {
  if (
    vbaiInfrastructureStatus === null ||
    vbaiInfrastructureStatus === undefined
  ) {
    return "ready";
  }

  if (vbaiInfrastructureStatus === StepStatus.InProgress) {
    return "inProgress";
  }

  if (vbaiInfrastructureStatus === StepStatus.Failed) {
    return "failed";
  }

  return "success";
};

const parseMlStatus = (status: boolean | null): Status => {
  if (status === null) {
    return "inProgress";
  }

  if (!status) {
    return "failed";
  }

  return "success";
};

const columns: ColumnsType<TenantRow> = [
  {
    title: "Logo",
    dataIndex: "logo",
    key: "logo",
    width: "200px",
    render: (value) => <SmallLogo logoUrl={value} />,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Full Name",
    dataIndex: "fullName",
    key: "fullName",
  },
  {
    title: "VBAI Infrastracture Status",
    dataIndex: "vbaiStatus",
    key: "vbaiStatus",
    render: (value) => <Chip type={value} />,
    width: "200px",
  },
  {
    title: "ML Infrastracture Status",
    dataIndex: "mlStatus",
    key: "mlStatus",
    render: (value) => <Chip type={value} />,
    width: "200px",
  },
];
