import { Table, Tooltip } from "antd";
import { useEffect } from "react";
import { connect } from "react-redux";
import { getSteps } from "../../pages/Home/Home.slice";
import { RootState } from "../../redux/rootReducer";
import { AppDispatch } from "../../redux/store";
import { StepStatus as StepStatusEnum } from "../../types/creationStatus";
import { TableContent } from "./CreationSteps.style";
import ErrorToolitipMessage from "./ErrorToolitipMessage/ErrorToolitipMessage";
import {
  GridSelectorResult,
  prepareStepStatusesDataSelector,
} from "./gridSelector";

type Props = {
  tenantName: string;
  isCreating: boolean;
  gridData: GridSelectorResult;
  onGetSteps: (tenantName: string) => void;
};

function CustomRow(props: any) {
  const getRow = () => <tr {...props} />;

  if (props.children[0]?.props?.record?.status === StepStatusEnum.Failed) {
    return (
      <Tooltip
        title={<ErrorToolitipMessage row={props.children[0]?.props?.record} />}
      >
        {getRow()}
      </Tooltip>
    );
  }

  return getRow();
}

const CreationSteps = ({
  tenantName,
  isCreating,
  gridData,
  onGetSteps,
}: Props) => {
  useEffect(() => {
    if (
      !isCreating ||
      !gridData.dataSource.some((x) => x.status === StepStatusEnum.InProgress)
    ) {
      return;
    }

    const interval = setInterval(() => {
      onGetSteps(tenantName);
    }, 1000);

    return () => clearInterval(interval);
  }, [gridData.dataSource, isCreating, onGetSteps, tenantName]);

  const { dataSource, columns } = gridData;

  return (
    <TableContent className="steps-table">
      <Table
        scroll={{
          y: "38vh",
        }}
        components={{
          body: {
            row: CustomRow,
          },
        }}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
    </TableContent>
  );
};

const mapStateToProps = (state: RootState) => ({
  gridData: prepareStepStatusesDataSelector(state),
  isCreating: state.home.isCreating,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onGetSteps: (tenantName: string) => dispatch(getSteps(tenantName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreationSteps);
