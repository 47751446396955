import { ColumnsType } from "antd/lib/table/interface";
import { Tenant } from "../../../types/tenant";

export type AppLinkRow = {
  key: string;
  application: string;
  link: string;
};

const columns: ColumnsType<any> = [
  {
    title: "Application",
    dataIndex: "application",
    key: "application",
    width: "200px",
  },
  {
    title: "Link",
    dataIndex: "link",
    key: "link",
  },
];

export const prepareTableData = (tenant: Tenant) => {
  const dataSource: AppLinkRow[] = [
    {
      key: "is",
      application: "Identity Server",
      link: tenant.identityServerUrl,
    },
    {
      key: "im",
      application: "Inspection Planner",
      link: tenant.inspectionPlanerUrl,
    },
    {
      key: "st",
      application: "Stitiching Tool",
      link: tenant.stitchingToolUrl,
    },
    {
      key: "am",
      application: "Annotation Module",
      link: tenant.annotationModuleUrl,
    },
    {
      key: "ea",
      application: "Engineering Assessment",
      link: tenant.engineeringAssessmentUrl,
    },
    {
      key: "pt",
      application: "Prediction Tool",
      link: tenant.predictionToolUrl,
    },
    {
      key: "dar",
      application: "Data Analytics & Reporting",
      link: tenant.dataAnalyticsToolUrl,
    },
    {
      key: "r&r",
      application: "Result & Reporting",
      link: tenant.resultAndReportingUrl,
    },
    {
      key: "qm",
      application: "Quote Module",
      link: tenant.quoteModuleUrl,
    },
    {
      key: "rm",
      application: "Repair Module",
      link: tenant.repairModuleUrl,
    },
    {
      key: "bm",
      application: "Budget Module",
      link: tenant.budgetModuleUrl,
    },
    {
      key: "wm",
      application: "Warranty Module",
      link: tenant.warrantyModuleUrl,
    },
    {
      key: "du",
      application: "Data Upload & Download center Module",
      link: tenant.dataUploadAndDownloadCenterUrl,
    },
  ];

  if (tenant.name.toLowerCase() === "sgre") {
    dataSource.push({
      key: "intm",
      application: "Internal Inspection Manager",
      link: tenant.internalInspectionManagerUrl,
    });
  }

  return {
    dataSource,
    columns,
  };
};
