import { Status } from "../../../common";
import { theme } from "../../../styles/theme";
import { StepStatus } from "../../../types/creationStatus";

type StepStatusSettings = {
  color: string;
  text: string;
  status?: "success" | "exception" | "normal" | "active" | undefined;
  percentage: number;
};

const stepTypes = new Map<Status, StepStatusSettings>();
stepTypes.set("ready", {
  color: "",
  text: "Click 'Create' to start the Proces",
  percentage: 0,
  status: "normal",
});
stepTypes.set("inProgress", {
  color: theme.inProgress,
  text: "Creating...",
  percentage: 50,
  status: "active",
});
stepTypes.set("failed", {
  color: theme.failed,
  text: "Failed, please try again!",
  percentage: 100,
  status: "exception",
});
stepTypes.set("success", {
  color: theme.valid,
  text: "Created",
  percentage: 100,
  status: "success",
});

export const getStepTypeContent = (type: Status) => {
  return stepTypes.get(type)!;
};

export const prepareStatusValue = (status: StepStatus): Status => {
  if (!status) {
    return "ready";
  }

  if (status === StepStatus.Failed) {
    return "failed";
  }

  if (status === StepStatus.InProgress) {
    return "inProgress";
  }

  return "success";
};
