import "./App.scss";
import "antd/dist/antd.css";
import "react-image-crop/dist/ReactCrop.css";
import Router from "./Router";
import UserDetailsContext from "./common/UserDetails.context";
import Header from "./components/Header/Header";
import { AuthService } from "./services/AuthService";
import { useEffect, useState } from "react";
import { User } from "oidc-client";

function App() {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    AuthService.userManager.events.addUserLoaded((user: any) => {
      setUser(user);
    });
    AuthService.userManager.events.addUserSignedOut(() => {
      AuthService.redirectToAuthority();
    });

    AuthService.getUser().then((userFromStorage: User | null) => {
      if (!userFromStorage) return;
      setUser(userFromStorage);
    });
  }, []);

  return (
    <UserDetailsContext.Provider value={{ user: user }}>
      <div className="container">
        <Header />
        <Router />
      </div>
    </UserDetailsContext.Provider>
  );
}

export default App;
