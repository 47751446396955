import { Table } from "antd";
import React, { useEffect, useState } from "react";
import PageContainer from "../../components/PageContainer/PageContainer";
import CreateNew from "./modals/CreateNew/CreateNew";
import { SearchOutlined } from "@ant-design/icons";
import {
  InputSearch,
  PageActions,
  PageWrapper,
  TableContent,
  TableFilters,
} from "./Home.style";
import PrimaryButton from "../../components/Button/PrimaryButton";
import { connect } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { AppDispatch } from "../../redux/store";
import {
  clearTenant,
  createTenant,
  getTenants,
  retryTenant,
} from "./Home.slice";
import {
  GridSelectorResult,
  prepareTenantsGridDataSelector,
} from "./gridSelector";
import { CreateTenant, Tenant, TenantRow } from "../../types/tenant";
import { useNavigate } from "react-router-dom";
import { getAnyFailedStepSelector } from "../../common/anyFailedStepSelector";

type Props = {
  gridData: GridSelectorResult;
  isLoading: boolean;
  isCreating: boolean;
  anyStepFailed: boolean;
  selectedTenant: Tenant | null;
  onGetTenants: () => void;
  onCreateTenant: (newTenant: CreateTenant) => void;
  onRetry: (id: string) => void;
  onClear: () => void;
};

const Home = ({
  gridData,
  isLoading,
  isCreating,
  anyStepFailed,
  selectedTenant,
  onGetTenants,
  onCreateTenant,
  onRetry,
  onClear,
}: Props) => {
  const [visible, setVisible] = useState(false);
  const [searchField, setSearchField] = useState("");

  let navigate = useNavigate();

  useEffect(() => {
    onGetTenants();
  }, [onGetTenants]);

  useEffect(() => {
    if (!visible) {
      onClear();
    }
  }, [onClear, visible]);

  const onRowClicked = (id: string) => {
    navigate(`/tenants/${id}`);
  };

  const { dataSource, columns } = gridData;

  const filteredDataSource = dataSource.filter((x) =>
    x.name.toLowerCase().includes(searchField.toLowerCase())
  );

  return (
    <PageContainer pageTitle="Tenants">
      <PageWrapper>
        <TableFilters>
          <InputSearch
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
            suffix={<SearchOutlined />}
          />
        </TableFilters>
        <TableContent className="tenants-table">
          <Table
            loading={isLoading}
            dataSource={filteredDataSource}
            columns={columns}
            onRow={(record: TenantRow) => ({
              onClick: () => {
                onRowClicked(record.id);
              },
            })}
            rowClassName={(record: TenantRow) => {
              return `selectable`;
            }}
          />
        </TableContent>
      </PageWrapper>
      <PageActions>
        <PrimaryButton title="Create New" onClick={() => setVisible(true)} />
      </PageActions>
      <CreateNew
        tenant={selectedTenant}
        isCreating={isCreating}
        visible={visible}
        onCancel={() => setVisible(false)}
        onCreate={onCreateTenant}
        anyStepFailed={anyStepFailed}
        onRetry={onRetry}
      />
    </PageContainer>
  );
};

const mapStateToProps = (state: RootState) => ({
  gridData: prepareTenantsGridDataSelector(state),
  isLoading: state.home.isLoading,
  isCreating: state.home.isCreating,
  anyStepFailed: getAnyFailedStepSelector(state),
  selectedTenant: state.home.selectedTenant,
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onGetTenants: () => dispatch(getTenants()),
  onCreateTenant: (newTenant: CreateTenant) =>
    dispatch(createTenant(newTenant)),
  onRetry: (id: string) => dispatch(retryTenant(id)),
  onClear: () => dispatch(clearTenant()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
