import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../redux/rootReducer";
import { CreationStatus, StepStatus } from "../types/creationStatus";

const getStatusSteps = (state: RootState) => state.home.creationStatuses;

export const getAnyFailedStepSelector = createSelector(
  [getStatusSteps],
  (statusSteps) => {
    if (!statusSteps) {
      return false;
    }

    const dataSource = allSteps.map((step) => ({
      key: step.name,
      name: step.name,
      status: step.field(statusSteps),
    }));

    return dataSource.some(
      (x) =>
        x.status === StepStatus.Failed || x.status === StepStatus.NotStarted
    );
  }
);

export const allSteps = [
  {
    field: (step: CreationStatus) => step.awsResoruces,
    name: "AWS Resources",
  },
  {
    field: (step: CreationStatus) => step.sqlServer,
    name: "SqlServer",
  },
  {
    field: (step: CreationStatus) => step.postgreSQL,
    name: "PostgreSQL",
  },
  {
    field: (step: CreationStatus) => step.mongoDB,
    name: "MongoDB infrastructure",
  },
  {
    field: (step: CreationStatus) => step.elasticsearch,
    name: "Elasticsearch Infrastracture",
  },
  {
    field: (step: CreationStatus) => step.defaultData,
    name: "Default data",
  },
  {
    field: (step: CreationStatus) => step.mlServices,
    name: "ML Infrastracture",
  },
];
