import { Modal } from "antd";
import PageContainer from "../../../../components/PageContainer/PageContainer";
import {
  PageWrapper,
  FormContainer,
  StepsContainer,
  PageActions,
} from "./CreateNew.style";
import PrimaryButton from "../../../../components/Button/PrimaryButton";
import CreationSteps from "../../../../components/CreationSteps/CreationSteps";
import TenantForm from "../../../../components/TenantForm/TenantForm";
import { CreateTenant, Tenant } from "../../../../types/tenant";
import { useEffect, useState } from "react";
import { validTenantNameRegex } from "../../../../common";

type Props = {
  visible: boolean;
  isCreating: boolean;
  anyStepFailed: boolean;
  tenant: Tenant | null;
  onCancel: () => void;
  onCreate: (newTenant: CreateTenant) => void;
  onRetry: (name: string) => void;
};

const CreateNew = ({
  anyStepFailed,
  visible,
  isCreating,
  tenant,
  onCancel,
  onCreate,
  onRetry,
}: Props) => {
  const [file, setFile] = useState<File>();
  const [newFile, setNewFile] = useState<File>();
  const [tenantName, setTenantName] = useState("");
  const [tenantFullName, setTenantFullName] = useState("");

  const create = () => {
    const newTenant: CreateTenant = {
      file: newFile,
      name: tenantName,
      fullName: tenantFullName,
    };
    onCreate(newTenant);
  };

  const retry = () => {
    if (!tenant) {
      return;
    }

    onRetry(tenant.id);
  };

  useEffect(() => {
    if (!visible) {
      setFile(undefined);
      setNewFile(undefined);
      setTenantName("");
      setTenantFullName("");
    }
  }, [visible]);

  const tenantFormParams = {
    file,
    newFile,
    tenantName,
    tenantFullName,
    setFile,
    setNewFile,
    setTenantName,
    setTenantFullName,
    readonly: !!tenant,
  };

  const isNameValid = validTenantNameRegex.test(tenantName);
  const canCreate =
    tenantName.length && tenantFullName.length && isNameValid && newFile;

  if (!visible) {
    return null;
  }

  return (
    <Modal
      visible={visible}
      footer={null}
      className="create-tenant"
      onCancel={onCancel}
    >
      <PageContainer pageTitle="Create New Tenant">
        <PageWrapper>
          <FormContainer>
            <TenantForm {...tenantFormParams} />
          </FormContainer>
          <StepsContainer>
            <CreationSteps tenantName={tenantName} />
          </StepsContainer>
        </PageWrapper>
        <PageActions>
          {!anyStepFailed && (
            <PrimaryButton
              disabled={!canCreate}
              loading={isCreating}
              title="Create"
              onClick={create}
            />
          )}
          {anyStepFailed && (
            <PrimaryButton
              disabled={!canCreate}
              loading={isCreating}
              title="Retry"
              onClick={retry}
            />
          )}
        </PageActions>
      </PageContainer>
    </Modal>
  );
};

export default CreateNew;
