import { styled } from "../../styles/theme";

export const HeaderContainer = styled.header`
  background-color: ${({ theme }) => theme.sgViolet};
  display: flex;
  justify-content: space-between;
  color: white;
  height: 80px;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 40px;
  margin-right: 40px;
`;

export const AppLogoContainer = styled(LogoContainer)`
  padding: 24px 0;
  cursor: pointer;
`;

export const SgLogoContainer = styled(LogoContainer)`
  padding: 28px 0;
`;

export const Logo = styled.img`
  height: 100%;
`;

export const HeaderRight = styled.div`
  display: flex;
`;

export const UserInfoContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const UserName = styled.div`
  background-color: ${({ theme }) => theme.onVioletItems};
  height: 40px;
  width: 220px;
  margin-right: 2px;
`;

export const UserSelect = styled.a`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px;
  color: white;
`;

export const UserSettings = styled.div`
  background-color: ${({ theme }) => theme.onVioletItems};
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
  cursor: pointer;
`;
