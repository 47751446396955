import React, { ReactNode } from "react";
import {
  HeaderH3,
  PageContent,
  PageContentContainer,
  PageHeader,
} from "./PageContainer.style";
import { fadeIn } from "../../styles/animations";

type Props = {
  pageTitle: string;
  children: ReactNode;
};

const PageContainer = ({ pageTitle, children }: Props) => {
  return (
    <PageContentContainer initial="hidden" animate="visible" variants={fadeIn}>
      <PageHeader>
        <HeaderH3>{pageTitle}</HeaderH3>
      </PageHeader>
      <PageContent>{children}</PageContent>
    </PageContentContainer>
  );
};

export default PageContainer;
