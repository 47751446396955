import React from "react";
import CenteredHeader from "../../components/CenteredHeader/CenteredHeader";
import { Link } from "react-router-dom";

const NoMatch = () => {
  return (
    <CenteredHeader
      content={
        <>
          <span>This route does not exist, please </span>
          <Link to="/">go to home page</Link>
        </>
      }
    />
  );
};

export default NoMatch;
