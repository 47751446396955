import { styled } from "../../../styles/theme";

export const TenantApplicationContainer = styled.div`
  background-color: white;
  padding: 10px;
  flex: 0.6;
`;

export const TableContent = styled.div`
  padding: 10px;
`;

export const PageHeader = styled.div`
  margin: 15px 0;
`;

export const HeaderH3 = styled.h3`
  font-weight: 200;
  margin-left: 30px;
`;
