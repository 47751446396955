import { StepStatus as StepStatusEnum } from "../../../types/creationStatus";
import { Icon, StepResultContainer } from "./StepResult.style";
import done from "../../../icons/done.png";
import failed from "../../../icons/failed.png";
import inProgress from "../../../icons/inProgress.png";
import notStarted from "../../../icons/notStarted.png";

type Props = {
  status: StepStatusEnum;
};

const StepResult = ({ status }: Props) => {
  const getIcon = () => {
    if (!status) {
      return <Icon src={notStarted} />;
    }

    if (status === StepStatusEnum.Failed) {
      return <Icon src={failed} />;
    }

    if (status === StepStatusEnum.InProgress) {
      return <Icon src={inProgress} />;
    }

    return <Icon src={done} />;
  };

  return <StepResultContainer>{getIcon()}</StepResultContainer>;
};

export default StepResult;
