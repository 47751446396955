import { useCallback, useEffect, useRef, useState } from "react";
import { Upload } from "antd";
import ReactCrop, { Crop } from "react-image-crop";
import PrimaryButton from "../../Button/PrimaryButton";
import { DraggerProps } from "antd/lib/upload";
import { getCroppedImg } from "./utils";
import { CropContainer, CropContainerText } from "./UploadAndCrop.style";
import { fadeIn } from "../../../styles/animations";

const { Dragger } = Upload;

type Props = {
  file?: File;
  newFile?: File;
  isEdit?: boolean;
  setFile: (file?: File) => void;
  setNewFile: (file?: File) => void;
};

const UploadAndCrop = ({ file, isEdit, setNewFile }: Props) => {
  const imgRef = useRef(null);

  const [, setFullFile] = useState<File>();
  const [fullImgSrc, setFullImgSrc] = useState<string>();
  const [crop, setCrop] = useState<Partial<Crop>>({
    unit: "%",
    width: isEdit ? 110 : 30,
    aspect: 6 / 1,
  });

  useEffect(() => {
    if (file) {
      var reader = new FileReader();
      reader.onloadend = function () {
        setFullImgSrc(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  }, [file, isEdit]);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const onCropComplete = (crop: Crop) => {
    makeClientCrop(crop);
  };

  async function makeClientCrop(crop: Crop) {
    if (imgRef && crop.width && crop.height) {
      const croppedImage = await getCroppedImg(imgRef.current, crop);

      if (croppedImage) {
        setNewFile(croppedImage);
      }
    }
  }

  const props: DraggerProps = {
    multiple: false,

    beforeUpload: (newFile) => {
      setFullFile(newFile);

      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setFullImgSrc(reader.result! as string);
      });
      reader.readAsDataURL(newFile);

      return false;
    },
    showUploadList: false,
  };

  const isImageSelected = !!fullImgSrc;

  return (
    <div style={{ marginTop: "12px" }}>
      <Dragger {...props} openFileDialogOnClick={!isImageSelected}>
        <div style={{ display: "flex", flexDirection: "column", padding: 10 }}>
          {!isImageSelected && (
            <CropContainerText
              initial="hidden"
              animate="visible"
              variants={fadeIn}
            >
              <span style={{ fontWeight: "bold", alignSelf: "flex-start" }}>
                Drag & drop your files here
              </span>
              <span style={{ alignSelf: "flex-start", fontSize: 12 }}>
                The maximum file size you can upload is 10 MB
              </span>
            </CropContainerText>
          )}
          {isImageSelected && (
            <CropContainer initial="hidden" animate="visible" variants={fadeIn}>
              <ReactCrop
                style={{ transition: ".3s" }}
                src={fullImgSrc}
                crop={crop}
                onChange={(c, pc) => setCrop(pc)}
                onImageLoaded={onLoad}
                onComplete={onCropComplete}
              />
            </CropContainer>
          )}
          <Upload
            {...props}
            openFileDialogOnClick={isImageSelected}
            className="upload-button"
          >
            <PrimaryButton title="Browse Files" onClick={() => {}} />
          </Upload>
        </div>
      </Dragger>
    </div>
  );
};

export default UploadAndCrop;
