import { Status } from "../../common";
import { BasicChip } from "./Chip.style";
import { getChipTypeContent } from "./utils";

type Props = {
  type?: Status;
};

const Chip = ({ type }: Props) => {
  if (!type) {
    return null;
  }

  const value = getChipTypeContent(type);

  if (!value) {
    return null;
  }

  return <BasicChip color={value.color}>{value.text}</BasicChip>;
};

export default Chip;
